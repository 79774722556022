export const TRANSLATIONS_ES = {
    traduction: "Traducción",
    training: "Capacitación",

    audiovisualServices: "Servicios Audiovisuales",
    ourProfessionals: "Nuestros Profesionales",
    publications: "Publicaciones",
    module: "Módulo {{count}}",
    contacto: "Contacto",

    homeTitle: "EXCELTI",
    homeSubtitle: "Equipo de traductores e intérpretes comprometidos con la profesión.",
    homeParagraph: "Dirigido por Olga Álvarez-Barr",
    homeInfoFooterText: "Los invitamos a contactarse con nosotros. Será un gusto poder escucharlos y orientarlos en función de sus necesidades.",
    interpretation: 'Interpretación',

    //Página traducción
    asesoria: "Asesoramiento:",
    asesoriaParagraph: "Empresas, organizaciones, organizadores de conferencias, otros clientes en materia de producción de textos a traducir y  otros servicios audiovisuales relacionados con la traducción.",
    tecnic: "Técnica:",
    tecnicParagraph: " Manuales, especificaciones, patentes, folletos, gacetillas, catálogos.",
    cientific: "Científica:",
    cientificParagraph: " Resúmenes, protocolos, informes, presentaciones.",
    comercial: "Comercial:",
    comercialParagraph: " Contratos, poderes, estatutos, balances.",
    public: "Pública:",
    publicParagraph: " Documentación personal, programas de estudios, certificados analíticos, diplomas, documentación notarial y judicial.",
    oral: "Oral:",
    oralParagraph: " Versión oral (grabada o",
    oralParagraph2: ") de material escrito.",
    oralParagraphI: "in situ",
    correction: "Corrección y edición:",
    correctionParagraph: " Mejora de textos originales.",
    transcAudio: "Transcripción de audios:",
    transcAudioParagraph: " Conversión de audio a un texto escrito.",
    tradAudio: "Traducción de audios:",
    tradAudioParagraph: " Conversión de audio en un idioma a un texto escrito en otro idioma.",
    translatedBooks: "Libros traducidos",
    teamTranslates: 'Gestión de proyectos de traducción en equipo',
    otherPublishedTranslates: "Otras traducciones publicadas",

    //Página interpretación
    asesoramientoEnTraduccion: "Asesoramiento en interpretación",
    asesoramientoEnTraduccionContent: "Asesoramiento para organizadores de conferencias, empresas, organizaciones y otros clientes en materia de contratación de intérpretes. Formación y gestión de equipos de intérpretes en congresos, convenciones, seminarios, actividades de capacitación, negociaciones, conferencias de prensa y otro tipo de reuniones en las que los participantes de diversas lenguas y culturas requieran la interpretación para poder comunicarse.  Asesoramiento sobre otros servicios audiovisuales relacionados con la interpretación.",
    simultanea: "Simultánea",
    simultaneaContent: "Los intérpretes trabajan en una cabina, aislados acústicamente del resto de la reunión, y se valen del equipamiento específico para este servicio para interpretar a los oradores de manera simultánea.",
    consecutiva: "Consecutiva",
    consecutivaContent: "El intérprete se ubica al lado de los delegados o del orador e interpreta una vez que estos concluyen cada una de sus intervenciones.",
    susurrada: "Susurrada",
    susurradaContent: "Interpretación simultánea sin equipamiento. El intérprete susurra la traducción para un número reducido de asistentes situados en la proximidad.",
    susurradaContentI: "",
    interpretacionRemota: "Interpretación remota",
    interpretacionRemotaContent: "Los participantes de la reunión se encuentran en diferentes sedes y se comunican a través de medios de telecomunicación, plataformas de videoconferencias o de interpretación.",
    eventosHibridos: "Eventos híbridos",
    eventosHibridosContent: "Modalidad en la que se combina la interpretación presencial con la virtual. ",
    actuacionPericial: "Actuación pericial",
    actuacionPericialContent: "Servicios de traductores públicos como peritos intérpretes.",

    //Página capacitación
    modalidad: 'Modalidades',
    coaching: 'Coaching',
    cursoInterpretacion: 'Curso de interpretación',
    coachingSecondTitle: '(Español-Inglés-Portugués)',
    cursoSecondTitle: '(Inglés - Español)',
    trainingParagraph: " Todos los integrantes de Excelti estamos comprometidos con nuestra propia capacitación y con la de nuestros colegas, a los que orientamos en función de su perfil personal",
    cursoSubTitle: 'Metodología de aprendizaje personalizada y con fines específicos',

    traductores: 'Traductores',
    interpretes: 'Intérpretes',
    estudiantes: 'Estudiantes',
    profesionales: "Profesionales",
    institucionesInternacionales: 'Instituciones internacionales',

    //Modalidad
    modalidadSubTitle: 'Presencial y remota',
    modalidadVirtual: 'Virtual (2020-Actualidad)',
    modalidadMoodle: 'Moodle',
    modalidadElearningPlataforma: 'Plataforma de E-Learning',
    modalidadBlended: 'B-learning',
    modalidadGroup: 'Clases',
    modalidadIndividual: 'Tutorías',
    modalidadComunicacion: 'Contactos e intercambios',
    modalidadTeorica: 'Distribución de material teórico y de ejercitación',
    modalidadElearning: 'E-learning',

    subVideo: " Subtitulado de videos:",
    subVideoParagraph: "TEXTO SUB AUDIO",
    traductora: "Traductora",
    interpreter: "Intérprete",
    teacher: "Docente",
    leader: "Líder",

    //Footer
    secciones: 'SECCIONES',
};